<template>
  <AddTemplate />
</template>

<script>
// @ is an alias to /src
import AddTemplate from "@/components/PushTemplates/TemplateForm.vue";

export default {
  name: "AddTemplates",
  components: {
    AddTemplate,
  },
};
</script>
