<template>
  	<AddSinglePin />
</template>

<script>

// @ is an alias to /src
import AddSinglePin from "@/components/AddSinglePin.vue";

export default {
  name: "AddSinglePins",
  components: {
    AddSinglePin,
  },
};
</script>
