<template>
  	<AddPinBatch />
</template>

<script>

// @ is an alias to /src
import AddPinBatch from "@/components/AddPinBatch.vue";

export default {
  name: "AddPinBatchs",
  components: {
    AddPinBatch,
  },
};
</script>
