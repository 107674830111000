<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} App</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="box">
                  <div class="box-header">
                    <h3 class="box-title">Fill up push template information</h3>
                  </div>
                  <div class="box-body form-box-body form-horizontal">
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Template Name
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <input
                          name="name"
                          id="name"
                          class="form-control input-sm"
                          v-model="name"
                          value=""
                          placeholder="Template Name"
                          type="text"
                        />
                        <label v-if="error_name" class="error" for="name">{{
                          error_name
                        }}</label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >App Name
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <select
                          title=""
                          name="app_id"
                          id="app_id"
                          v-model="app_id"
                          v-select="app_id"
                          class="form-control input-sm select2"
                        >
                          <option value="">-- Select --</option>
                          <option
                            v-for="(data, index) in appArr"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.app_name }}
                          </option>
                        </select>
                        <label v-if="error_app_id" class="error" for="app_id">{{
                          error_app_id
                        }}</label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Title
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <input
                          name="heading"
                          v-model="heading"
                          id="heading"
                          class="form-control input-sm"
                          value=""
                          placeholder="Enter Push Title"
                          type="text"
                        />
                        <label
                          v-if="error_heading"
                          class="error"
                          for="heading"
                          >{{ error_heading }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Message
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <textarea
                          name="push_message"
                          v-model="push_message"
                          id="push_message"
                          class="form-control input-sm"
                          cols="30"
                          rows="5"
                          placeholder="Enter Push Message"
                        ></textarea>
                        <label
                          v-if="error_push_message"
                          class="error"
                          for="heading"
                          >{{ error_push_message }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Image URL</label>
                      <div class="col-md-8">
                        <input
                          name="big_picture"
                          v-model="big_picture"
                          id="big_picture"
                          class="form-control input-sm"
                          value=""
                          placeholder="Image URL"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >URL for Android</label
                      >
                      <div class="col-md-8">
                        <input
                          name="launch_url_android"
                          v-model="launch_url_android"
                          id="launch_url_android"
                          class="form-control input-sm"
                          value=""
                          placeholder="URL for Android"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">URL for iOS</label>
                      <div class="col-md-8">
                        <input
                          name="launch_url_ios"
                          v-model="launch_url_ios"
                          id="launch_url_ios"
                          class="form-control input-sm"
                          value=""
                          placeholder="URL for iOS"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Page Control</label>
                      <div class="col-md-8">
                        <input
                          name="page_control"
                          v-model="page_control"
                          id="page_control"
                          class="form-control input-sm"
                          value=""
                          placeholder="Page Control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Is Active</label>
                      <div class="col-md-3">
                        <input
                          name="status"
                          type="checkbox"
                          v-model="status"
                          id="status"
                          checked="checked"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 btnRight">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  class="btn btn-sm btn-submit btn-submit-big"
                  @click="formSubmit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddTemplates",
  data() {
    return {
      id: "",
      name: "",
      app_id: "",
      heading: "",
      push_message: "",
      big_picture: "",
      launch_url_android: "",
      launch_url_ios: "",
      page_control: "",
      status: true,
      appArr: {},

      error_name: false,
      error_app_id: false,
      error_heading: false,
      error_push_message: false,

      isEditing: false,
      alert_message: "",
      LabelFormName: "Add Push template",
    };
  },
  created() {
    var rootObject = this;
    this.loadAppDropdown();
    if (this.$route.params.id) {
      rootObject.LabelFormName = "Edit";
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    loadAppDropdown() {
      loader.show();
      let rootObject = this;
      axios
        .get("get-app-dropdown-data")
        .then(function (response) {
          //console.log(response.data);
          rootObject.appArr = response.data;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    getEditData() {
      let rootObject = this;
      axios
        .get("push-template/" + rootObject.id)
        .then(function (response) {
          //console.log(response.data);
          rootObject.name = response.data.name;
          rootObject.heading = response.data.heading;
          rootObject.push_message = response.data.push_message;
          rootObject.app_id = response.data.app_id;
          rootObject.big_picture = response.data.big_picture;
          rootObject.launch_url_android = response.data.launch_url_android;
          rootObject.launch_url_ios = response.data.launch_url_ios;
          rootObject.page_control = response.data.page_control;
          rootObject.status = false;
          if (response.data.status == 1) {
            rootObject.status = true;
          }

          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_name = false;
          rootObject.error_heading = false;
          rootObject.error_app_id = false;
          rootObject.error_push_message = false;
        }, 5000);
        return 0;
      }

      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("name", this.name);
      form.append("heading", this.heading);
      form.append("push_message", this.push_message);
      form.append("app_id", this.app_id);
      form.append("big_picture", this.big_picture);
      form.append("launch_url_android", this.launch_url_android);
      form.append("launch_url_ios", this.launch_url_ios);
      form.append("page_control", this.page_control);

      let status = this.status === true ? 1 : 0;
      form.append("status", status);

      if (rootObject.isEditing) {
        form.append("_method", "put");
        querystr = "/" + rootObject.id;
      }
      //console.log(querystr)

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("push-template" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          rootObject.loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.app_id) {
        this.error_app_id = "This field is required";
        has_error = true;
      }

      if (!this.name) {
        this.error_name = "This field is required";
        has_error = true;
      }

      if (!this.heading) {
        this.error_heading = "This field is required";
        has_error = true;
      }

      if (!this.push_message) {
        this.error_push_message = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
