<template>
  	<AddRatePlan />
</template>

<script>

// @ is an alias to /src
import AddRatePlan from "@/components/AddRatePlan.vue";

export default {
  name: "AddRatePlans",
  components: {
    AddRatePlan,
  },
};
</script>
