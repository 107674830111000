<template>
  	<EditReseller />
</template>

<script>

// @ is an alias to /src
import EditReseller from "@/components/EditReseller.vue";

export default {
  name: "EditResellers",
  components: {
    EditReseller,
  },
};
</script>
