<template>
  <select title="" id="short_filter" name="short_filter" class="form-control input-sm select2">
    <option value="">Filter Duration</option>
	<option value="1">Today</option>
	<option value="2">Yesterday</option>
	<option value="3">This Week</option>
	<option value="4">Last Week</option>
	<option value="5">Last 7 Days</option>
	<option value="6">This Month</option>
	<option value="8">Last 15 Days</option>
	<option value="7">Last 30 Days</option>
  </select>
</template>
<script>
   	export default {
    	name: "filterDropdownOptions",
   	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>