<template>
   <div class="content-wrapper">
      <section class="content-header">
         <div class="row">
            <div class="col-xs-6">
               <h1>Add New Rate Plan </h1>
            </div>
            <div class="col-xs-6 mandatoryDiv">
               <span class="mandatory_color">(*)</span> <span class="letter-right"> Marked are required field</span>
            </div>
         </div>
      </section>
      <section class="content">
         <div class="row">
            <div class="col-sm-12">
               <div class="alert alert-info" v-if="alert_message">
                  {{alert_message}}
               </div>
            </div>
            <form role="form" id="formID">
               <div class="col-sm-6">
                  <div class="box">
                     <div class="box-header">
                        <h3 class="box-title">
                           Basic Rate Plan Information                            
                        </h3>
                     </div>
                     <div class="box-body form-box-body form-horizontal">
                        <div class="form-group">
                           <label class="col-md-4 control-label">Rate Plan Name <span class="mandatory_horizontal">*</span></label>
                           <div class="col-md-8">
                              <input name="name" id="name" v-model="name" class="form-control input-sm" value="" placeholder="Rate Plan Name" type="text" onfocus="this.removeAttribute('readonly');" readonly="">
                              <label v-if="error_name" class="error" for="name">{{error_name}}</label>
                           </div>
                        </div>
                        <div class="form-group">
                           <label class="col-md-4 control-label">Description</label>
                           <div class="col-md-8">
                              <textarea class="form-control input-sm" name="description" placeholder="description" v-model="description" id="description" onfocus="this.removeAttribute('readonly');" readonly=""></textarea>
                           </div>
                        </div>
                        <div class="form-group">
                           <label class="col-md-4 control-label">Active</label>
                           <div class="col-md-8">
                              <input name="status" type="checkbox" id="status" value="1" checked="checked" v-model="status">
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-12 btnRight">
                     <input type="submit" name="submit" value="Submit" class="btn btn-sm btn-submit btn-submit-big" @click="formSubmit">
                  </div>
               </div>
            </form>
         </div>
      </section>
   </div>
</template>
<script>
   import axios from 'axios'
   export default {
      name: "AddRatePlan",
      data() {
         return {
            name : '',
            description : '',
            status : true,
            alert_message : '',

            error_name: false,
         }
      },
      created(){
         
      },
      methods: {
         formSubmit(e) {
            e.preventDefault();
            var rootObject = this;
            if (this.status===true) {
               var status = 1;
            }else{
               var status = 2;
            }

            var result = this.fromValidation();
            if (result) {
               return 0;
            }

            loader.show();
            const form = new FormData()
            form.append('name', this.name)
            if(this.description){
               form.append('description', this.description)
            }
            form.append('status', status)

            var config = {
             headers: { 'Content-Type': 'multipart/form-data' }
            }

            axios.post('rate-plan', form, config)
            .then(function (response) {
               //console.log(response);
               rootObject.alert_message =  "Rate Plan Successfully Created";
               loader.hide();
               rootObject.resetFrom();
            })
            .catch(function (error) {
               let alertmessage = "";
               for (var prop in error.response.data) {
                   alertmessage += ' '+error.response.data[prop]
               }
               console.log(alertmessage);
               loader.hide();
               rootObject.alert_message =  alertmessage;
               rootObject.com_errorHandel(error);
            });

            setTimeout(function(){  
               rootObject.alert_message = '';
            }, 5000);
         },

         fromValidation(reset = false){
            var has_error = false;
            if(!this.name){
               this.error_name = "This field is required";
               has_error = true;
            }

            if (has_error) {
               return true;
            }else{
               return false;
            }
         },

         resetFrom(){
            this.error_name = false;
            this.name =  "";
            this.description =  "";
            this.status =  true;
         }
      },
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>