<template>
  <AppList />
</template>

<script>
// @ is an alias to /src
import AppList from "@/components/AppLists/AppLists.vue";

export default {
  name: "AppLists",
  components: {
    AppList,
  },
};
</script>
