<template>
   <div class="content-wrapper">
      <section class="content-header">
         <div class="row">
            <div class="col-xs-6">
               <h1>Edit Batch Subscribers</h1>
            </div>
            <div class="col-xs-6 mandatoryDiv">
               <span class="mandatory_color">(*)</span> <span class="letter-right"> Marked are required field</span>
            </div>
         </div>
      </section>
      <section class="content">
         <div class="row">
            <form>
               <div class="col-sm-12">
                  <div class="alert alert-info" v-if="alert_message">
                     {{alert_message}}
                  </div>
               </div>
               <div class="col-sm-6">
                  <div class="box">
                     <div class="box-header">
                        <h3 class="box-title">
                           Basic Configuration
                        </h3>
                     </div>
                     <div class="box-body form-box-body form-horizontal">
                        <div class="form-group">
                           <label class="col-md-4 control-label">Batch Name <span class="mandatory_horizontal">*</span></label>
                           <div class="col-md-8">
                              <input name="description" id="description" class="form-control input-sm stop-specialchar" value="" placeholder="Batch Name" type="text" onfocus="this.removeAttribute('readonly');" readonly="" v-model="description">
                              <label v-if="error_description" class="error" for="description">{{error_description}}</label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-12 btnRight">
                     <input type="submit" name="submit" value="Save" class="btn btn-sm btn-submit btn-submit-big" @click="formSubmit">
                  </div>
               </div>
            </form>
         </div>
      </section>
   </div>
</template>
<script>
   import axios from 'axios'
   export default {
      name: "EditBatch",
      data() {
         return {
            id : 0,
            description : '',
            alert_message : '',
   
            error_description: false,
         }
      },
      created(){
         loader.show();
         var batch_id = this.$route.params.id;
         var rootObject = this;
         axios.get('batches/'+batch_id).then(function (response) {
            //console.log(response);
           if(response.data.data == null){
             $.alert('No Data found or Data mismatch!');
             rootObject.$router.push({name:"PinBatchLists"})
           }else {
             rootObject.description = response.data.data.description
             rootObject.id = response.data.data.lot_id
           }
            loader.hide();
         })
         .catch(function (error) {
            let alertmessage = "";
            for (var prop in error.response.data) {
                alertmessage += ' '+error.response.data[prop]
            }
            console.log(alertmessage);
            rootObject.alert_message =  alertmessage;
            loader.hide();
            rootObject.com_errorHandel(error);
         });
      },
      methods: {
         formSubmit(e) {
            e.preventDefault();
            var rootObject = this;
   
            var result = this.fromValidation();
            if (result) {
               return 0;
            }
   
            const form = new FormData()
            form.append('description', this.description)
            form.append('_method', 'put')
   
            var config = {
             headers: { 'Content-Type': 'multipart/form-data' }
            }
   
            axios.post('batches/'+this.id, form, config)
            .then(function (response) {
               //console.log(response);
               rootObject.alert_message =  "Batch Name Successfully Updated";
            })
            .catch(function (error) {
               let alertmessage = "";
               for (var prop in error.response.data) {
                   alertmessage += ' '+error.response.data[prop]
               }
               console.log(alertmessage);
               rootObject.alert_message =  alertmessage;
               rootObject.com_errorHandel(error);
            });
   
            setTimeout(function(){  
               rootObject.alert_message = '';
            }, 3000);
         },
   
         fromValidation(reset = false){
            var has_error = false;
            if(!this.description){
               this.error_description = "This field is required";
               has_error = true;
            }
   
            if (has_error) {
               return true;
            }else{
               return false;
            }
         },

      },

      mounted: function() {
         $(".stop-specialchar").keypress(function(e){
            var keyCode = e.keyCode || e.which;
            var regexp = new RegExp("^[a-zA-Z0-9_ ]+$");
            var result = regexp.test(String.fromCharCode(keyCode));
            if(!result){
               return false;
            }
         });
      }
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>