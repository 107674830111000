<template>
  	<AddNewReseller />
</template>

<script>

// @ is an alias to /src
import AddNewReseller from "@/components/AddNewReseller.vue";

export default {
  name: "AddNewResellers",
  components: {
    AddNewReseller,
  },
};
</script>
