<template>
   <header class="main-header">
      <router-link :to="{name: 'Home'}" class="logo" :style="'background: '+panelData.switch_header_color" style="color: #fff !important; font-weight: normal !important;">
        <span class="logo-mini"><b>{{(panelData.com_name).toUpperCase()}}</b></span>
        <span class="logo-lg">{{(panelData.com_name).toUpperCase()}}</span>
      </router-link>
      <nav class="navbar navbar-static-top" role="navigation" :style="'background: '+panelData.switch_header_color">
         <a href="#" class="sidebar-toggle" data-toggle="offcanvas" role="button">
         <span class="sr-only">Toggle navigation</span>
         </a>
         <div class="navbar-custom-menu">
            <ul class="nav navbar-nav">
               <li class="user user-menu">
                  <a href="#" @click="logout">
                     <i class="fa fa-sign-out"></i>
                     <span class="hidden-xs">Logout</span>
                  </a>
               </li>
            </ul>
         </div>
      </nav>
   </header>
</template>
<script>
   import {mapGetters} from 'vuex'
   export default {
     name: "AppHeader",
     data(){
      return{
        userData : {},
      }
     },
     created(){
        var encryptedData = this.$store.getters.getUser;
        var decodedString  = this.com_DecryptString(encryptedData);
        var userData = JSON.parse(decodedString);
        this.userData = userData;
     },
     methods: {
      logout(e) {
        loader.hide();
        e.preventDefault();
        let rootObject = this;
        let loginparam = {
          'jwt_token': '',
          'redirect_url':'login',
        };
        rootObject.com_setLoginData(loginparam);
      }
     },
     computed: {
       ...mapGetters({
         panelData : 'getPanelData'
       })
     },
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>