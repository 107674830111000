<template>
  	<HourlyServerConnection />
</template>

<script>

// @ is an alias to /src
import HourlyServerConnection from "@/components/reports/HourlyServerConnection.vue";

export default {
  name: "HourlyServerConnectionView",
  components: {
    HourlyServerConnection,
  },
};
</script>
