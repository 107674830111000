<template>
  	<IpBundleDtlsList />
</template>

<script>

// @ is an alias to /src
import IpBundleDtlsList from "@/components/IpBundle/IpBundleDtlsList.vue";

export default {
  name: "IpBundleDtlsLists",
  components: {
	  IpBundleDtlsList,
  },
};
</script>
