<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-7">
          <h1>Server Connection</h1>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="searchReportData">
        <div class="row">
          <div class="col-sm-3 col-xs-6 form-group">
            <input
              placeholder="From"
              type="text"
              value=""
              name="starttime"
              id="starttime"
              v-model="starttime"
              class="form-control input-sm datePicker"
              required=""
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title=""
              name="server_id"
              id="server_id"
              v-model="server_id"
              v-select="server_id"
              class="form-control input-sm select2"
              @change="searchReportData"
            >
              <option value="">All Server</option>
              <option
                v-for="(data, index) in server_list"
                :key="index"
                :value="index"
              >
                {{ data }}
              </option>
            </select>
          </div>
          <div
            class="col-sm-2 col-xs-6 form-group btnLeft"
            style="margin-bottom: 0"
          >
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">Server Wise Connection Report</h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <div class="table-responsive">
                  <table class="table table-0024 table-bordered table-striped">
                    <thead>
                      <tr>
                        <th class="tdCenter">SL</th>
                        <th class="tdCenter">Server Name</th>
                        <th class="tdCenter" v-for="h in 24" :key="h">
                          {{ h - 1 + ":00" }}
                        </th>
                        <!--<th class="tdCenter">Current</th>-->
                        <th class="tdCenter">Capacity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, k, index) in $store.state
                          .wsServerLoadConnection"
                        :key="data.id"
                      >
                        <td class="tdCenter tdSN">{{ index + 1 }}</td>
                        <td class="tdCenter">
                          {{ data.severName }}
                        </td>

                        <td
                          class="tdCenter"
                          :class="
                            getHourlyValue(data[hr - 1], data.capacity, k, hr)
                          "
                          v-for="hr in 24"
                          :key="hr"
                        >
                          {{ data[hr - 1] }}
                        </td>

                        <!-- <td class="tdCenter" :class="getHourlyValue(data['current_connected'], data.capacity, k, 13)" >
                                         {{data['current_connected']}}
                                     </td>-->
                        <td class="tdCenter">
                          {{ data.capacity }}
                        </td>
                      </tr>

                      <tr></tr>

                      <tr
                        v-if="$store.state.wsServerLoadConnection.length == 0"
                      >
                        <td
                          colspan="27"
                          class="tdCenter"
                          style="padding: 30px 0"
                        >
                          <b>No Records Found</b>
                        </td>
                      </tr>
                      <tr v-else class="info">
                        <td class="tdCenter" colspan="2">Grand Total</td>
                        <td
                          v-for="val in 24"
                          :key="val"
                          style="font-weight: bold; text-align: center"
                        >
                          {{ lineTotalAmount[val - 1] }}
                        </td>
                        <!--<td  style="font-weight: bold; text-align: center;">
                                       {{totalCurrentLoad}}
                                    </td>-->
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
import axios from "axios";
export default {
  name: "HourlyServerConnection",
  data() {
    return {
      listArr: {},
      lineTotalAmount: [],
      server_list: {},
      server_id: "",
      starttime: "",
      serversArray: [],
      ws_connections: {},
      totalCurrentLoad: 0,
    };
  },
  created() {
    this.filterWiseDateRangeSet();
    this.searchReportData();
  },
  methods: {
    searchReportData() {
      loader.show();
      var rootObject = this;

      const form = new FormData();
      form.append("recorded_date", this.starttime);
      form.append("vpn_server_id", this.server_id);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios.post("get-vpnserver-names").then(function (response) {
        rootObject.server_list = response.data;
      });

      axios
        .post("reports/vpn-server-connections", form, config)
        .then(function (response) {
          rootObject.populateReportData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateReportData(response) {
      var dataArray = response.data;
      var lineTotalAmount = [];
      this.listArr = dataArray;
      this.$store.dispatch("setWsServerLoadConnection", dataArray);
      var ind = 0;
      for (var serverId in dataArray) {
        this.serversArray[ind] = dataArray[serverId]["serverIp"];
        ind++;
        for (let hr = 0; hr < 24; hr++) {
          if (lineTotalAmount[hr] == undefined) {
            if (dataArray[serverId][hr] == undefined) {
              lineTotalAmount[hr] = 0;
            } else {
              lineTotalAmount[hr] = parseInt(dataArray[serverId][hr]);
            }
          } else {
            if (dataArray[serverId][hr]) {
              lineTotalAmount[hr] =
                parseInt(lineTotalAmount[hr]) +
                parseInt(dataArray[serverId][hr]);
            }
          }
        }
      }
      //console.log(lineTotalAmount);
      this.lineTotalAmount = lineTotalAmount;
      loader.hide();
    },

    filterWiseDateRangeSet() {
      $(".datePicker").removeAttr("readonly", "readonly");
      if ($("#short_filter").val()) {
        this.starttime = "";
        $(".datePicker").attr("readonly", "readonly");
      } else {
        var dateRangeArr = this.com_currentDateRange(-0);
        this.starttime = dateRangeArr.startDate;
      }
    },
    getHourlyValue(val, capacity, serverId, hr) {
      var currentValue = 0;
      var bgColor = null;
      if (!isNaN(val)) {
        switch (true) {
          case val > (95 / 100) * capacity:
            bgColor = "red2";
            break;
          case val >= (80 / 100) * capacity && val < (96 / 100) * capacity:
            bgColor = "orange2";
            break;
          case val > (60 / 100) * capacity && val < (80 / 100) * capacity:
            bgColor = "yellow2";
            break;
          case val >= (40 / 100) * capacity && val < (61 / 100) * capacity:
            bgColor = "green2";
            break;
          case val >= (20 / 100) * capacity && val < (40 / 100) * capacity:
            bgColor = "blue2";
            break;
          case val < (20 / 100) * capacity:
            bgColor = "lightGray";
            break;
          default:
            bgColor = "";
            break;
        }
        currentValue = val;
      }
      //gTotalArr[serverId][hr] = gTotalArr[serverId][hr] + currentValue;
      return bgColor;
    },
  },
  mounted: function () {
    var rootObject = this;

    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
