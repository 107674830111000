<template>
  <AddPush />
</template>

<script>
// @ is an alias to /src
import AddPush from "@/components/Push/PushForm.vue";

export default {
  name: "AddPushs",
  components: {
    AddPush,
  },
};
</script>
