<template>
  <VpnInstaller />
</template>

<script>
// @ is an alias to /src
import VpnInstaller from "@/components/VpnInstallationForm.vue";

export default {
  name: "InstallOvpn",
  components: {
    VpnInstaller,
  },
};
</script>
