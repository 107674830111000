<template>
  	<ResellerList />
</template>

<script>

// @ is an alias to /src
import ResellerList from "@/components/ResellerList.vue";

export default {
  name: "ResellerLists",
  components: {
    ResellerList,
  },
};
</script>
