<template>
  	<PasswordReset />
</template>

<script>

// @ is an alias to /src
import PasswordReset from "@/components/PasswordReset.vue";

export default {
  name: "passwordResetView",
  components: {
    PasswordReset,
  },
};
</script>
