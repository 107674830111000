<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <div class="user-panel">
        <div class="pull-left image">
          <img
            src="/assets/img/defaultProPic.png"
            class="img-circle"
            alt="User Image"
          />
        </div>
        <div class="pull-left info">
          <p>{{ userData.reseller.full_name }}</p>
          <a href="#"> <i class="fa fa-circle text-success"></i> Online </a>
        </div>
      </div>
      <ul class="sidebar-menu">
        <li :class="DashboardActive == true ? 'active' : ''">
          <router-link :to="{ name: 'Home' }">
            <i class="ion ion-ios-speedometer"></i>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li
          :class="
            subscriptionMenuActive == true ? 'treeview active' : 'treeview'
          "
        >
          <a href="#">
            <i class="fa fa-user-circle"></i>
            <span>Subscribers</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'ResellerLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Resellers</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'PinLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Subscribers List</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'AddPinBatchs' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Generate Subscribers Batch</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PinBatchLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Subscribers Batch List</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ResellerConnectedUsers' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Connected Subscribers</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="ipActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-braille"></i><span>VPN Servers</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'IpLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN List</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'IpBundleLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>VPN Bundles</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="serverActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-server"></i><span>Servers</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'Addservers' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Add New Server</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'ServerLists' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Server List</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="pushActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-bell"></i><span>Push Management</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'AppList' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>App List</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'TemplateList' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Push Template</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PushList' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Send Push</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="rateActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-calculator"></i><span>Rates Section</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'AddRatePlans' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Add New Rate Plan</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'RatePlans' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Rate Plan</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li :class="reportsActive == true ? 'treeview active' : 'treeview'">
          <a href="#">
            <i class="fa fa-line-chart"></i><span>Reports</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'PinSubscriptionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Subscription Report</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'HourlyServerConnectionsView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Hourly Server Connection</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'HourlySubscriptionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Hourly Subscription</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'OnlineTransactionReportView' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Online Payment Report</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          :class="settingsActive == true ? 'treeview active' : 'treeview'"
          v-if="setting_access"
        >
          <a href="#">
            <i class="fa fa-cog"></i><span>Settings</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-left pull-right"></i>
            </span>
          </a>
          <ul class="treeview-menu">
            <li>
              <router-link :to="{ name: 'RadiusAuthList' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Radius Server</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AddConfigSettings' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>App Config</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'AddPanelSettings' }">
                <i class="ion ion-arrow-right-b"></i>
                <span>Panel</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  </aside>
</template>
<script>
import axios from "axios";
export default {
  name: "LeftSideBar",
  data() {
    return {
      userData: {},
      setting_access: false,
      reportsActive: false,
      rateActive: false,
      subscriptionMenuActive: false,
      settingsActive: false,
      DashboardActive: false,
      serverActive: false,
      ipActive: false,
      pushActive: false,
    };
  },
  created() {
    this.activeInactiveSideBar();
    this.setting_access = this.com_verifyAllowOrNotSetting();
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    //console.log(this.userData);
  },
  methods: {
    activeInactiveSideBar() {
      let routeName = this.$route.name;
      if (
        routeName == "PinSubscriptionReportView" ||
        routeName == "ResellerTransactionReportView" ||
        routeName == "PaymentReportView" ||
        routeName == "IndividualSoldPinReportView" ||
        routeName == "HourlySubscriptionReportView" ||
        routeName == "OnlineTransactionReportView"
      ) {
        this.reportsActive = true;
      }

      if (
        routeName == "AddRatePlans" ||
        routeName == "RatePlans" ||
        routeName == "EditRatePlans" ||
        routeName == "SaleingRatePlans" ||
        routeName == "EditSaleRates"
      ) {
        this.rateActive = true;
      }

      if (
        routeName == "PinLists" ||
        routeName == "AddPinBatchs" ||
        routeName == "PinBatchLists" ||
        routeName == "ResellerConnectedUsers" ||
        routeName == "AddSinglePins" ||
        routeName == "EditBatchs" ||
        routeName == "PinListBatchWises" ||
        routeName == "ResellerLists"
      ) {
        this.subscriptionMenuActive = true;
      }

      if (
        routeName == "AddPanelSettings" ||
        routeName == "AddConfigSetting" ||
        routeName == "RadiusAuthList" ||
        routeName == "AddRadiusAuth" ||
        routeName == "AddRadiusAuth"
      ) {
        this.settingsActive = true;
      }

      if (routeName == "Home") {
        this.DashboardActive = true;
      }
      if (
        routeName == "Addservers" ||
        routeName == "ServerLists" ||
        routeName == "Addservers"
      ) {
        this.serverActive = true;
      }
      if (
        routeName == "IpLists" ||
        routeName == "IpBundleLists" ||
        routeName == "IpBundleAdd" ||
        routeName == "AddIp"
      ) {
        this.ipActive = true;
      }

      if (
        routeName == "AppList" ||
        routeName == "AddApp" ||
        routeName == "TemplateList" ||
        routeName == "AddTemplate" ||
        routeName == "PushList" ||
        routeName == "AddPush"
      ) {
        this.pushActive = true;
      }
    },
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
