<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>VPN Server List</h1>
        </div>
        <div class="col-xs-2">
          <router-link :to="{ name: 'AddIp' }" class="btn btn-sm btn-add">
            <i class="fa fa-plus-square"></i>Add New Server
          </router-link>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="Server Name"
              type="text"
              v-model="name"
              name="name"
              id="name"
              class="form-control input-sm"
              value=""
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="IP"
              type="text"
              v-model="ip"
              name="ip"
              id="ip"
              class="form-control input-sm"
              value=""
              autocomplete="off"
            />
          </div>
          <div class="col-xs-6 col-sm-2 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="type"
              id="type"
              class="form-control input-sm select2"
              v-model="type"
              v-select="type"
              @change="dopagination(0)"
            >
              <option value="">Filter Type</option>
              <option value="1">Open VPN UDP</option>
              <option value="6">Open VPN TCP</option>
              <option value="5">WireGuard</option>
              <option value="4">ShadowSocks</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-2 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="status"
              id="status"
              class="form-control input-sm select2"
              v-model="status"
              v-select="status"
              @change="dopagination(0)"
            >
              <option value="">Filter Status</option>
              <option value="1">Active</option>
              <option value="2">Inactive</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-1 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="limit"
              id="limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="display_limit"
              v-select="display_limit"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100" selected>100</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-1 ZRP form-group btnLeft">
            <button
              class="btn btn-sm btn-submit"
              id="apply"
              @click="dopagination(0)"
            >
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }} Showing {{ dispplayFrom }} to
                {{ dispplayTo }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table
                  class="table table-0024 table-bordered table-striped table-scroll"
                >
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Name</th>
                      <th class="tdCenter">IP</th>
                      <th class="tdCenter">VPN Server</th>
                      <th class="tdCenter">Platform</th>
                      <th class="tdCenter">Note</th>
                      <th class="tdCenter">Country (Code)</th>
                      <th class="tdCenter">Protocol</th>
                      <!--<th class="tdCenter">Protocol Status</th>
                                        <th class="tdCenter">Online Connected Users</th>-->
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataCollections" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(sl) + parseInt(index) }}
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditIp', params: { id: data.id } }"
                          :title="data.name"
                        >
                          {{ data.name }}
                        </router-link>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditIp', params: { id: data.id } }"
                          :title="data.name"
                        >
                          {{ data.ip }}
                        </router-link>
                      </td>
                      <td class="tdCenter">
                        {{ data.vpn_server_name }}
                      </td>
                      <td class="tdCenter">
                        {{ data.platform }}
                      </td>
                      <td class="tdCenter">
                        {{ data.note == "null" ? "" : data.note }}
                      </td>
                      <td class="tdCenter">
                        {{ data.country_name + "(" + data.country_code3 + ")" }}
                      </td>
                      <td class="tdCenter">
                        <span class="label label-success" v-if="data.type == 1"
                          >Open VPN UDP</span
                        >
                        <span
                          class="label label-warning"
                          v-else-if="data.type == 6"
                          >Open VPN TCP</span
                        >
                        <span
                          class="label label-primary"
                          v-else-if="data.type == 5"
                          >WireGuard</span
                        >

                        <span
                          class="label label-warning"
                          v-else-if="data.type == 4"
                          >ShadowSocks</span
                        >
                        <span class="label label-default" v-else>Unknown</span>
                      </td>

                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.status == 1"
                          >Active</span
                        >
                        <span class="label label-danger" v-else>Inctive</span>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditIp', params: { id: data.id } }"
                          title="Edit"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>
                        &nbsp;
                        <a @click="removeData(data)"
                          ><i class="ion ion-ios-trash table-delete"></i
                        ></a>
                        &nbsp;
                        <a
                          @click="downloadConf(data)"
                          v-if="
                            data.type == 1 ||
                            data.type == 6 ||
                            data.type == 5 ||
                            data.type == 4
                          "
                          title="Download Config"
                          ><i class="fa fa-download" style="font-size: 15px"></i
                        ></a>
                      </td>
                    </tr>
                    <tr v-if="dataCollections.length == 0">
                      <td colspan="13" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >
                  « Previous
                </a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">
                        ..
                      </span>
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">
                      {{ index }}
                    </span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a
                  v-if="next_page_url"
                  href="#"
                  @click="dopagination(nextPage)"
                  class="next"
                >
                  Next »
                </a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "IpLists",
  data() {
    return {
      ws_connections: {},
      dataCollections: [],
      user_data: {},
      name: "",
      ip: "",
      type: "",
      status: "",
      server_for: "",
      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 100,
      serversArray: [],
      sl: 1,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    this.dopagination(0);
  },
  methods: {
    removeData(data) {
      var rootObject = this;
      let msg = "Do you really want to Delete?";

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            axios
              .delete(`ips/${data.id}`)
              .then((response) => {
                rootObject.alert_message = response.data.message; // "Deleted Successfully !";
                setTimeout(function () {
                  rootObject.alert_message = "";
                }, 3000);
                rootObject.dopagination(0);
              })
              .catch((error) => console.error(error));
          },
          no: function () {
            //return;
          },
        },
      });
    },

    downloadConf(data) {
      var rootObject = this;
      var decodedString = null;

      if (data.type == 1 || data.type == 6) {
        const conf = data.type == 1 ? data.udp_conf : data.tcp_conf;
        if (conf == "" || conf == null) {
          $.alert("Please, Check config data. Config can not be empty.");
          return;
        }

        var ipArr = data.ip.split(":");
        try {
          decodedString = atob(conf);
        } catch (e) {
          $.alert(
            "Invalid Config Data! Please, Check config data is base64 encoded or not."
          );
          return;
        }

        var replacedIp = decodedString.replace("remoteIP", ipArr[0]);
        var finalResult = replacedIp.replace("remotePort", ipArr[1]);
        rootObject.generateDownloadFile(finalResult, "openvpn", data.name);
      } else if (data.type == 4) {
        var ipArr = data.ip.split(":");
        var configData = JSON.parse(data.config);
        configData["server"] = ipArr[0];
        configData["server_port"] = ipArr[1];
        var finalResult = JSON.stringify(configData);
        rootObject.generateDownloadFile(finalResult, "shadowsocks", data.name);
      } else {
        loader.show();
        const form = new FormData();
        form.append("username", "username");
        form.append("pass", "pass");
        form.append("vpnserverId", data.vpn_server_id);
        form.append("ipId", data.id);
        form.append("connection_type", 1);
        form.append("isAdmin", true);
        var config = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        axios
          .post("wireguard-user-con-download", form, config)
          .then(function (response) {
            //console.log(response);
            loader.hide();
            rootObject.generateDownloadFile(
              response.data,
              "wireguard",
              data.name
            );
          })
          .catch(function (error) {
            let alertmessage = "";
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
            console.log(alertmessage);
            loader.hide();
            rootObject.alert_message = alertmessage;
            rootObject.com_errorHandel(error);
          });
      }
    },

    generateDownloadFile(result, protocolType, name) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(result)
      );
      if (protocolType == "openvpn") {
        element.setAttribute("download", name + ".ovpn");
      } else if (protocolType == "shadowsocks") {
        element.setAttribute("download", name + ".json");
      } else {
        element.setAttribute("download", name + ".conf");
      }
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;

      let querystr =
        "name=" +
        this.name +
        "&ip=" +
        this.ip +
        "&type=" +
        this.type +
        "&status=" +
        this.status +
        "&server_for=" +
        this.server_for +
        "&paginate=" +
        this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("ips?" + querystr)
        .then(function (response) {
          rootObject.populateData(response);
          var listData = response.data.data;

          if (listData.length > 0) {
            for (var ind in listData) {
              rootObject.serversArray[ind] = listData[ind].vpn_server_ip;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateData(response) {
      var responseData = response.data;
      //console.log(responseData.data);
      this.sl = parseInt(responseData.from);
      this.dataCollections = responseData.data;
      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
