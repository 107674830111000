<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-8">
          <h1>Search Subscriber</h1>
        </div>
        <div class="col-xs-4">
          <router-link
            :to="{ name: 'AddSinglePins' }"
            class="btn btn-sm btn-add"
          >
            <i class="fa fa-plus-square"></i> Generate Single Subscribers
          </router-link>
          <a class="btn btn-sm btn-add refresh" @click="refreshNow">
            <i class="fa fa-refresh"></i> Refresh
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-xs-6 col-sm-2 form-group">
          <input
            placeholder="Search Subscriber"
            type="text"
            name="login"
            v-model="login"
            id="login"
            class="form-control input-sm"
            value=""
          />
        </div>
        <div class="col-xs-6 col-sm-2 form-group">
          <select
            title=""
            name="idLots"
            id="lots_id"
            class="form-control input-sm select2"
            v-model="lots_id"
            v-select="lots_id"
            @change="dopagination(0)"
          >
            <option value="0">Select Batch Name</option>
            <option
              v-for="data in batchArr"
              :key="data.id"
              value=""
              :value="data.lot_id"
            >
              {{ data.description }}
            </option>
          </select>
        </div>
        <div class="col-xs-6 col-sm-2 form-group">
          <select
            title=""
            name="plan_details_id"
            id="plan_details_id"
            v-model="plan_details_id"
            v-select="plan_details_id"
            class="form-control input-sm select2"
            @change="dopagination(0)"
          >
            <option value="">All Rate Plans</option>
            <option
              v-for="(data, index) in rate_plan_list"
              :key="data.id"
              :value="index"
            >
              {{ data }}
            </option>
          </select>
        </div>
        <div class="col-xs-6 col-sm-2 form-group">
          <select
            title=""
            name="status"
            id="status"
            v-model="status"
            v-select="status"
            class="form-control input-sm select2"
            @change="dopagination(0)"
          >
            <option value="">All</option>
            <option value="1">Active Subscribers</option>
            <option value="2">Inactive Subscribers</option>
            <option value="3">Expired Subscribers</option>
            <option value="4">All Subscribed</option>
            <option value="5">Not subscribed (Active)</option>
          </select>
        </div>
        <div class="col-xs-6 col-sm-2 form-group">
          <input
            placeholder="Search UDID"
            type="text"
            name="udid"
            id="udid"
            v-model="udid"
            class="form-control input-sm"
            value=""
          />
        </div>
        <div class="col-xs-6 col-sm-2 form-group">
          <select
            title=""
            name="limit"
            id="limit"
            v-select="display_limit"
            v-model="display_limit"
            class="form-control input-sm select2"
            @change="dopagination(0)"
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        <div class="col-xs-6 col-sm-3 form-group btnRight pull-right">
          <a class="btn btn-sm btn-submit" @click="dopagination(0)">
            <i class="fa fa-search"></i> Search
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <form>
              <div class="box-header">
                <h3 class="box-title">
                  Total : {{ totalData }} Showing {{ dispplayFrom }} to
                  {{ dispplayTo }}
                  <div class="form-inline pull-right" id="activation1">
                    <label style="color: green; font-weight: bold"
                      >With Selected</label
                    >
                    <div class="form-group">
                      <select
                        title=""
                        id="activation_option1"
                        name="activation"
                        class="form-control input-sm"
                        v-model="operation_type"
                      >
                        <option value="1">Activate</option>
                        <option value="2">Deactivate</option>
                      </select>
                    </div>
                    <input
                      class="btn btn-sm btn-submit"
                      type="button"
                      name="button"
                      value="Submit"
                      @click="mulOperation(1)"
                    />
                  </div>
                </h3>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table class="table table-0024 table-bordered table-striped">
                    <thead>
                      <tr>
                        <th class="tdCenter tdSN">SN</th>
                        <th class="tdCenter">Account Id</th>
                        <th class="tdCenter">Subscribers</th>
                        <th class="tdCenter">Password</th>
                        <th class="tdCenter">Batch</th>
                        <th class="tdCenter">Activate At</th>
                        <th class="tdCenter">First login</th>
                        <th class="tdCenter">Last login</th>
                        <th class="tdCenter">Expire Date</th>
                        <th class="tdCenter">Expired At</th>
                        <th class="tdCenter">No of days</th>
                        <th class="tdCenter">Country</th>
                        <th class="tdCenter">Status</th>
                        <th class="tdCenter">
                          <span class="thSelectAll">All</span>
                          <input
                            type="checkbox"
                            id="check_all"
                            @click="checkAll"
                          />
                        </th>
                        <th class="tdCenter" style="width: 60px">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in pinListArr" :key="data.id">
                        <td class="tdCenter tdSN">
                          {{ parseInt(sl) + index }}
                        </td>
                        <td class="tdCenter tdSN">
                          {{ com_modifyAccountID(data.created_at, data.id) }}
                        </td>
                        <td class="tdCenter">
                          {{
                            com_removeHostedPinPrefix(
                              data.username,
                              userData.reseller4.hosted_pin_prefix,
                              userData.reseller4.reseller_panel_type
                            )
                          }}
                        </td>
                        <td class="tdCenter">
                          <span :id="'passwordShowDiv_' + data.id">
                            <button
                              type="button"
                              @click="showPinPass(data.id)"
                              class="btn btn-sm btn-submit bg-yellow"
                              style="border-radius: 2px"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                          </span>
                        </td>
                        <td class="tdCenter">
                          {{ data.lot_name }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.activate_at }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.after_activate_first_login_at }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.last_login }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.validity_date }}
                        </td>
                        <td class="tdCenter info">
                          {{ data.expired_at }}
                        </td>
                        <td class="tdCenter">
                          {{ data.no_of_days }}
                        </td>
                        <td class="tdCenter">
                          {{ data.country }}
                        </td>
                        <td class="tdCenter" v-if="data.status == 1">
                          <span class="label label-success">Active</span>
                        </td>
                        <td class="tdCenter" v-else-if="data.status == 2">
                          <span class="label label-danger">Inactive</span>
                        </td>
                        <td class="tdCenter" v-else>
                          <span class="label label-warning">Expire</span>
                        </td>
                        <td class="tdCenter">
                          <input type="hidden" :value="data.status" />
                          <input
                            class="user_id"
                            name="user_id[]"
                            type="checkbox"
                            :value="data.id"
                          />
                        </td>
                        <td class="tdCenter">
                          <a
                            v-if="
                              data.status == 1 &&
                              data.udid &&
                              userData.reseller.pin_reset == 1 &&
                              userData.reseller4_id != 98 &&
                              userData.reseller4_id != 304
                            "
                            title="Reset UDID"
                            class="btn btn-sm text-warning"
                            @click="resetPin(data.id)"
                          >
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="pinListArr.length == 0">
                        <td
                          colspan="14"
                          class="tdCenter"
                          style="padding: 30px 0"
                        >
                          <b>No Records Found</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="pagination" v-if="last_page > 1">
                  <a
                    v-if="prev_page_url"
                    href="#"
                    @click="dopagination(prevPage)"
                    class="next"
                  >
                    « Previous
                  </a>
                  <span v-else class="disabled">« Previous</span>
                  <span v-if="last_page > max_display_pagination">
                    <span v-for="index in last_page">
                      <span v-if="index == 1 || index == 2">
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                        <span v-if="index == 2 && firstDotted">..</span>
                      </span>
                      <span v-if="randomPageNumber.includes(index)">
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                      </span>
                      <span v-if="index == last_page || index == last_page - 1">
                        <span v-if="index == last_page - 1 && lastDotted">
                          ..
                        </span>
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">
                            {{ index }}
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <span v-for="index in last_page">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                  </span>
                  <a
                    v-if="next_page_url"
                    href="#"
                    @click="dopagination(nextPage)"
                    class="next"
                  >
                    Next »
                  </a>
                  <span v-else class="disabled">Next »</span>
                </div>
              </div>
              <div class="box-footer"></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "PinListBatchWise",
  data() {
    return {
      userData: {},
      pinListArr: [],
      batchArr: {},
      rate_plan_list: {},
      login: "",
      udid: "",
      plan_details_id: "",
      lots_id: 0,

      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      status: 1,
      sl: 1,
      operation_type: 1,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    loader.show();
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);

    var rootObject = this;
    axios
      .get("batch-pin-dropdown")
      .then(function (response) {
        rootObject.batchArr = response.data.batchData;
        rootObject.lots_id = rootObject.$route.params.id;
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        rootObject.com_errorHandel(error);
      });

    axios
      .get("rate-plan-details")
      .then(function (response) {
        rootObject.rate_plan_list = response.data.ratePlanDtlsData;
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        rootObject.com_errorHandel(error);
      });

    this.dopagination(0);
  },
  methods: {
    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      var lots_id = this.$route.params.id;
      let querystr =
        "login=" +
        this.login +
        "&status=" +
        this.status +
        "&paginate=" +
        this.display_limit +
        "&batch_id=" +
        lots_id +
        "&udid=" +
        this.udid +
        "&plan_details_id=" +
        this.plan_details_id;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("pins?" + querystr)
        .then(function (response) {
          rootObject.populatePinListData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populatePinListData(response) {
      this.sl = parseInt(response.data.from);

      var current_date_time = this.com_currentDateTime();
      var userData = this.userData;
      for (var prop in response.data.data) {
        var allowresetpin = false;
        var showPeriod = this.com_modifyDateTime(
          3,
          response.data.data[prop]["after_activate_first_login_at"]
        );
        if (showPeriod >= current_date_time) {
          if (
            response.data.data[prop]["reseller_level"] == userData.user_level
          ) {
            allowresetpin = true;
          }
        }
        response.data.data[prop]["allowresetpin"] = allowresetpin;
      }
      this.pinListArr = response.data.data;

      this.totalData = response.data.total;
      this.dispplayFrom = response.data.from;
      this.dispplayTo = response.data.to;
      this.display_limit = response.data.per_page;
      this.paginationGenerate(response);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.data.prev_page_url;
      this.next_page_url = response.data.next_page_url;
      this.last_page = response.data.last_page;
      this.current_page = response.data.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    resetPin(pin_id) {
      //console.log(pin_id);
      var rootObject = this;
      let msg = "Are you sure to reset this udid?";

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            loader.show();
            axios
              .get("reset-udid/" + pin_id)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "UDID Reset successfully";
                  loader.hide();
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            //return;
          },
        },
      });
    },

    checkAll() {
      if ($("#check_all").prop("checked") == true) {
        $(".user_id").prop("checked", "checked");
      } else {
        $(".user_id").prop("checked", false);
      }
    },

    mulOperation(type) {
      var rootObject = this;

      var checked = document.querySelectorAll(
        'input[name="user_id[]"]:checked'
      ).length;
      if (checked == 0) {
        $.alert("Please select at least a PIN");
        return;
      }

      /*status count message start*/
      var activePins = [];
      var inActivePins = [];
      var active_count = 0;
      var inactive_count = 0;
      $('input[name="user_id[]"]:checked').each(function () {
        const status = $(this).prev().val();
        if (status == 1) {
          active_count += 1;
          activePins.push($(this).val());
        } else {
          inactive_count += 1;
          inActivePins.push($(this).val());
        }
      });
      var activate_message =
        active_count > 0 ? `${active_count} PIN's is active.` : "";
      var in_activate_message =
        inactive_count > 0 ? `${inactive_count} PIN's is Inactive.` : "";
      /*status count message end*/

      var activation_option = this.operation_type;

      if (activation_option == 1) {
        if (inactive_count < 1) {
          $.alert("All PIN's are already active.");
          return;
        } else {
          var msg = ` ${activate_message} ${in_activate_message} Are you sure you want to activate ${inactive_count} PIN's?`;
        }

        var users = inActivePins;
      }

      if (activation_option == 2) {
        if (active_count < 1) {
          $.alert("All PIN's are already Inactive.");
          return;
        } else {
          var msg = `${in_activate_message} ${activate_message} Are you sure you want to deactivate ${active_count} PIN's?`;
        }

        var users = activePins;
      }

      //console.log(users);

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            loader.show();
            const form = new FormData();
            form.append("operation_type", activation_option);
            form.append("users", JSON.stringify(users));
            var config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            axios
              .post("bulk-pin-operation", form, config)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "Pin's " + response.data;
                  rootObject.checkAll();
                  loader.hide();
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            return;
          },
        },
      });

      //console.log(this.operation_type);
    },

    refreshNow() {
      this.login = "";
      this.udid = "";
      this.plan_details_id = "";
      this.status = 1;
      this.lots_id = this.$route.params.id;
      this.dopagination(0);
    },

    showPinPass(val) {
      let rootObject = this;
      var id = val;
      $("#passwordShowDiv_" + id).html(
        '<img src="/assets/img/loading_h.gif" alt="Loading"/>'
      );
      const form = new FormData();
      form.append("id", id);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("show-pin-pass", form, config)
        .then(function (response) {
          console.log(response);
          if (response.status == 200) {
            $("#passwordShowDiv_" + id).html(response.data);
          }
        })
        .catch(function (error) {
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          $.alert(alertmessage);
        });
    },
  },

  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
