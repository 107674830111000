<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} App</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="box">
                  <div class="box-header">
                    <h3 class="box-title">APP Information Form</h3>
                  </div>
                  <div class="box-body form-box-body form-horizontal">
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >App Name
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <input
                          name="app_name"
                          id="app_name"
                          class="form-control input-sm"
                          v-model="app_name"
                          value=""
                          placeholder="App Name"
                          type="text"
                        />
                        <label
                          v-if="error_app_name"
                          class="error"
                          for="app_name"
                          >{{ error_app_name }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >App ID
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <input
                          name="push_app_id"
                          v-model="push_app_id"
                          id="push_app_id"
                          class="form-control input-sm"
                          value=""
                          placeholder="App ID (One Signal)"
                          type="text"
                        />
                        <label
                          v-if="error_push_app_id"
                          class="error"
                          for="push_app_id"
                          >{{ error_push_app_id }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >App Key
                        <span class="mandatory_horizontal">*</span></label
                      >
                      <div class="col-md-8">
                        <input
                          name="push_app_key"
                          v-model="push_app_key"
                          id="push_app_key"
                          class="form-control input-sm"
                          value=""
                          placeholder="App Key (One Signal)"
                          type="text"
                        />
                        <label
                          v-if="error_push_app_key"
                          class="error"
                          for="push_app_key"
                          >{{ error_push_app_key }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Reseller Name</label
                      >
                      <div class="col-md-8">
                        <select
                          title=""
                          name="id_reseller"
                          id="id_reseller"
                          v-model="id_reseller"
                          v-select="id_reseller"
                          class="form-control input-sm select2"
                        >
                          <option value="">All reseller</option>
                          <option
                            v-for="(data, index) in resellerArr"
                            :key="index"
                            :value="index"
                          >
                            {{ data }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 btnRight">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  class="btn btn-sm btn-submit btn-submit-big"
                  @click="formSubmit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddApps",
  data() {
    return {
      id: "",
      app_name: "",
      push_app_id: "",
      push_app_key: "",
      id_reseller: "",
      resellerArr: {},

      error_app_name: false,
      error_push_app_id: false,
      error_push_app_key: false,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Create New",
    };
  },
  created() {
    var rootObject = this;
    this.loadResellerDropdown();
    if (this.$route.params.id) {
      rootObject.LabelFormName = "Edit";
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    loadResellerDropdown() {
      loader.show();
      let rootObject = this;
      axios
        .get("get-reseller-dropdown-data")
        .then(function (response) {
          //console.log(response.data.data);
          rootObject.resellerArr = response.data.data;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    getEditData() {
      let rootObject = this;
      axios
        .get("onesignal-apps/" + rootObject.id)
        .then(function (response) {
          //console.log(response.data);
          rootObject.app_name = response.data.app_name;
          rootObject.push_app_id = response.data.push_app_id;
          rootObject.push_app_key = response.data.push_app_key;
          if (response.data.id_reseller > 0) {
            rootObject.id_reseller =
              response.data.id_reseller + ":" + response.data.reseller_level;
          }
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_app_name = false;
          rootObject.error_push_app_id = false;
          rootObject.error_push_app_key = false;
        }, 5000);
        return 0;
      }

      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("app_name", this.app_name);
      form.append("push_app_id", this.push_app_id);
      form.append("push_app_key", this.push_app_key);
      form.append("id_reseller", this.id_reseller);

      if (rootObject.isEditing) {
        form.append("_method", "put");
        querystr = "/" + rootObject.id;
      }
      //console.log(querystr)

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("onesignal-apps" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          if (!rootObject.isEditing) {
            rootObject.app_name = "";
            rootObject.push_app_id = "";
            rootObject.push_app_key = "";
          }
          rootObject.loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.app_name) {
        this.error_app_name = "This field is required";
        has_error = true;
      }

      if (!this.push_app_id) {
        this.error_push_app_id = "This field is required";
        has_error = true;
      }

      if (!this.push_app_key) {
        this.error_push_app_key = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
