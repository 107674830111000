<template>
   <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
         <div class="box dashboard_report">
            <div class="box-header">
               <h3>
                  <b>Server Total Connected Users Graph</b> Date:<span id="report_5_duration_date_range">{{title}}</span>
               </h3>
               <div class="dash_interval" id="report_5_duration">
                  <a @click="report_5_duration(1)" :class="search_btn==1?'active':''">Today</a>
                  &nbsp;
                  <a @click="report_5_duration(22)" :class="search_btn==22?'active':''">Last 24 Hours</a>
                  &nbsp;
                  <a @click="report_5_duration(3)" :class="search_btn==3?'active':''" title="Last 7 Days">Last 7 Days</a>
                  &nbsp;
                  <a @click="report_5_duration(10)" :class="search_btn==10?'active':''" title="Last 30 Days">Last 30 Days</a>
                  &nbsp;
                  <a @click="report_5_duration(5)" title="Last 1 Year" :class="search_btn==5?'active':''">Last 12 Months</a>
               </div>
            </div>
            <div class="box-body">
               <div class="table-responsive" style="min-height: 250px; overflow: hidden;">
                  <img v-if="loading_graph_data" src="/assets/img/loading_h.gif" alt="Loading"/>
                  <div v-if="graph_data.length > 0" id="report_server_connection_data">
                  </div>
                  <div v-else id="report_server_connection_data"></div>
                  <span v-if="!loading_graph_data && graph_data.length==0" style="color: #de5d25;">No data Found within the date!</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: "ServerConnectionGraph",
    data() {
      return {
        loading_graph_data : true,
        search_btn : 1,
        graph_data : [],
        title : '',
      }
    },
    created(){
      //
    },
    methods: {
      report_5_duration(search_btn = 1){
        var rootObj = this;
        var responseData = [];
        this.title = '';
        this.loading_graph_data = true;
        $("#report_server_connection_data").html('');
        this.graph_data = [];
        this.search_btn = search_btn;
        this.title = "";

        const form = new FormData()
        form.append('reportId', this.search_btn)   
        var config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        axios.post('dashboard/total-server-connection-chart', form, config).then(function (response) {
          //console.log(response.data.data);
          responseData = response.data.data;
          if(responseData.length > 1){
            rootObj.graph_data = responseData;
            rootObj.drawChart(); 
          }
          rootObj.loading_graph_data = false;
          rootObj.title = response.data.dateRangeTitle;
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += ' '+error.response.data[prop]
          }
          rootObj.loading_graph_data = false;
          console.log(alertmessage);
          rootObj.com_errorHandel(error);
        });
      },
      drawChart () {
        const dataTable = google.visualization.arrayToDataTable(this.graph_data, false);
        const chart = new google.visualization.ColumnChart(document.getElementById('report_server_connection_data'));
        chart.draw(dataTable, null);
      },
    },
    mounted: function() {
      google.charts.load('current', {'packages':['corechart']});
      google.charts.setOnLoadCallback(this.report_5_duration);
    }
   
  };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>