<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>Server List</h1>
        </div>
        <div class="col-xs-2">
          <router-link
            :to="{ path: 'addnewserver' }"
            class="btn btn-sm btn-add"
          >
            <i class="fa fa-plus-square"></i>Add New Server
          </router-link>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-4 col-sm-2 form-group">
            <input
              placeholder="Server Name"
              type="text"
              v-model="name"
              name="name"
              id="name"
              class="form-control input-sm"
              value=""
              autocomplete="off"
            />
          </div>
          <div class="col-xs-4 col-sm-2 form-group">
            <input
              placeholder="IP"
              type="text"
              v-model="ip"
              name="ip"
              id="ip"
              class="form-control input-sm"
              value=""
              autocomplete="off"
            />
          </div>
          <div class="col-xs-4 col-sm-2 form-group" style="margin-bottom: 0">
            <select
              title=""
              name="limit"
              id="limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="display_limit"
              v-select="display_limit"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100" selected>100</option>
            </select>
          </div>
          <div class="col-xs-4 col-sm-2 ZRP form-group btnLeft">
            <button
              type="submit"
              class="btn btn-sm btn-submit"
              id="apply"
              @click="dopagination(0)"
            >
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }} Showing {{ dispplayFrom }} to
                {{ dispplayTo }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Server Name</th>
                      <th class="tdCenter">ID</th>
                      <th class="tdCenter">IP</th>
                      <th class="tdCenter">Capacity</th>
                      <th class="tdCenter">Port Speed</th>
                      <th class="tdCenter">Priority</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Installation Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in dataCollections"
                      :key="data.id"
                      :id="data.id"
                    >
                      <td class="tdCenter tdSN">
                        {{ parseInt(sl) + parseInt(index) }}
                      </td>
                      <td class="tdCenter">
                        {{ data.name }}
                      </td>
                      <td class="tdCenter">
                        {{ data.id }}
                      </td>
                      <td class="tdCenter">
                        {{ data.ip }}
                      </td>
                      <td class="tdCenter">
                        {{ data.capacity }}
                      </td>

                      <td class="tdCenter">
                        {{ data.bandwidth_capacity }}
                        {{ data.bandwidth_capacity_unit }}
                      </td>

                      <td class="tdCenter">
                        {{ data.order_priority }}
                      </td>

                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.status == 1"
                          >Active</span
                        >
                        <span class="label label-danger" v-else>Inctive</span>
                      </td>
                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.ovpn_status == 1"
                          >Installed & Ready</span
                        >
                        <span
                          class="label label-danger"
                          v-else-if="data.ovpn_status == 2"
                          >Repair Required</span
                        >
                        <span class="label label-danger" v-else
                          >Not Installed</span
                        >
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditServers', params: { id: data.id } }"
                          title="Edit"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>

                        <a style="margin-right: 5px" @click="removeServer(data)"
                          ><i class="ion ion-ios-trash table-delete"></i
                        ></a>
                        <router-link
                          :class="
                            data.ovpn_status == 3
                              ? 'btn btn-sm btn-success'
                              : 'btn btn-sm btn-warning'
                          "
                          :to="{
                            name: 'InstallOvpns',
                            params: { id: data.id },
                          }"
                          :title="
                            data.ovpn_status == 3 ? 'Install ' : 'Repair '
                          "
                        >
                          <span>{{
                            data.ovpn_status == 3 ? "Install " : "Repair "
                          }}</span>
                        </router-link>
                      </td>
                    </tr>
                    <tr v-if="dataCollections.length == 0">
                      <td colspan="15" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >
                  « Previous
                </a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">
                        ..
                      </span>
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">
                      {{ index }}
                    </span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a
                  v-if="next_page_url"
                  href="#"
                  @click="dopagination(nextPage)"
                  class="next"
                >
                  Next »
                </a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "RatePlan",
  data() {
    return {
      ws_connections: {},
      dataCollections: [],
      user_data: {},
      name: "",
      ip: "",
      path: "",
      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 100,
      status: "",
      sl: 1,
      serversArray: [],

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
      serversPayload: {},
    };
  },
  created() {
    this.dopagination(0);
  },
  methods: {
    removeServer(server) {
      var rootObject = this;
      let msg = "Do you really want to Delete?";
      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            axios
              .delete(`vpn-servers/${server.id}`)
              .then((response) => {
                rootObject.dopagination(0);
                rootObject.alert_message = "Server Deleted Successfully !";
                setTimeout(function () {
                  rootObject.alert_message = "";
                }, 3000);
              })
              .catch((error) => console.error(error));
          },
          no: function () {
            //return;
          },
        },
      });
    },

    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      let querystr =
        "name=" +
        this.name +
        "&ip=" +
        this.ip +
        "&path=" +
        this.path +
        "&paginate=" +
        this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("vpn-servers?" + querystr)
        .then(function (response) {
          rootObject.populateData(response);
        })
        .catch(function (error) {
          console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateData(response) {
      var responseData = response.data.data;
      this.sl = parseInt(responseData.from);
      this.dataCollections = responseData.data;
      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
