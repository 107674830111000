<template>
  <TemplateList />
</template>

<script>
// @ is an alias to /src
import TemplateList from "@/components/PushTemplates/TemplateLists.vue";

export default {
  name: "TemplateLists",
  components: {
    TemplateList,
  },
};
</script>
