<template>
  	<AddConfigSetting />
</template>

<script>

// @ is an alias to /src
import AddConfigSetting from "@/components/AddConfigSetting.vue";

export default {
  name: "AddConfigSettings",
  components: {
    AddConfigSetting,
  },
};
</script>
