<template>
  	<IpBundleList />
</template>

<script>

// @ is an alias to /src
import IpBundleList from "@/components/IpBundle/IpBundleLists.vue";

export default {
  name: "IpBundleLists",
  components: {
	  IpBundleList,
  },
};
</script>
