<template>
  <VpnAuthInstaller />
</template>

<script>
// @ is an alias to /src
import VpnAuthInstaller from "@/components/VpnAuthInstallationForm.vue";

export default {
  name: "InstallAuth",
  components: {
    VpnAuthInstaller,
  },
};
</script>
