<template>
  <EditRadius />
</template>

<script>
// @ is an alias to /src
import EditRadius from "@/components/EditRadiusAuth.vue";

export default {
  name: "EditRadiusAuth",
  components: {
    EditRadius,
  },
};
</script>
