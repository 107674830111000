<template>
  <div class="content-wrapper" style="background: rgb(237, 240, 242)">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-5">
          <h1>Dashboard</h1>
        </div>
        <div class="col-xs-7 mandatoryDiv">
          <span class="letter-right">
            Last Refresh Time: {{ refresh_datetime }}</span
          >
          <a class="btn btn-sm btn-add" @click="refreshDashboard"
            ><i class="fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-green"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Subscribed Users</span>

                  <span class="info-box-number" id="totalConnectedUsers">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ totSubscribedPin }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-yellow"
                  ><i class="fa fa-upload"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Uploads </span>
                  <span class="info-box-number" id="totalUploads">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ formatBytes(totalUploads) }}/s</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-green-gradient"
                  ><i class="fa fa-download"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Downloads</span>

                  <span class="info-box-number" id="totalDownloads">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ formatBytes(totalDownloads) }}/s</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Protocol wise totals-->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-warning"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Free Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ totalFreeUsers }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-aqua"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Connected OPENVPN Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{
                      totalUsersOpenvpnTcp + totalUsersOpenvpnUdp
                    }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-orange"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Connected WireGuard Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ totalUsersWireGuard }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Subscription Graph Start-->
      <SubscribePinsGraph v-if="renderComponent" />
      <!--Subscription Graph End-->

      <!--Subscription Graph Start-->
      <ServerConnectionGraph v-if="renderComponent" />
      <!--Subscription Graph End-->
    </section>
  </div>
</template>
<script>
import axios from "axios";
import ServerConnectionGraph from "@/components/dashboardComponents/ServerConnectionGraph.vue";
import SubscribePinsGraph from "@/components/dashboardComponents/SubscribePinsGraph.vue";

export default {
  name: "Dashboard",
  components: {
    SubscribePinsGraph,
    ServerConnectionGraph,
  },
  data() {
    return {
      renderComponent: true,
      refresh_datetime: "",
      loading_data: true,
      ts_rp: "",
      ts_wp: "",
      ts_sum: "",
      opc_today: "",
      opc_yes: "",
      yestotal: "",
      rpc_today: "",
      rpc_yes: "",
      rpc_sum: "",
      life_time_total_pins: "",
      totSubscribedPin: "",
      todSubscribedPin: "",
      life_time_total_pins_price: "",
      totRecharge: "",
      todRecharge: "",

      totalOnlineSubscribedPin: "",
      todayOnlineSubscribedPin: "",

      self_indicator: "",
      reseller_indicator: "",

      tbl1_loading_data: true,

      reseller_list: {},
      paymentArr: [],
      rechargeArr: [],
      topResellerArr: [],

      totalConnectedOvpnUsers: 0,
      totalFreeUsers: 0,

      //ws data
      ds_ws_connection_status: false,
      ws_connections: {},
      dsWsConnectOpenStatus: false,
      totalConnectedUsers: [],
      totalUsers: 0,

      totalUsersOpenvpnTcpArray: [],
      totalUsersOpenvpnTcp: 0,

      totalUsersOpenvpnUdpArray: [],
      totalUsersOpenvpnUdp: 0,

      totalUsersWireGuardArray: [],
      totalUsersWireGuard: 0,

      totalUploadsArray: [],
      totalDownloadsArray: [],
      totalUploads: 0,
      totalDownloads: 0,
    };
  },
  props: {
    msg: String,
  },
  created() {
    var rootObject = this;
    rootObject.loadDashboardData();
  },
  methods: {
    loadDashboardData() {
      var rootObj = this;
      loader.show();
      this.refresh_datetime = this.com_currentDateTime("dmy");

      const form = new FormData();
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("dashboard/load-dashboard-pin-count-data", form, config)
        .then(function (response) {
          var dashboardData = response.data.data;
          //console.log(dashboardData);

          rootObj.loading_data = false;
          rootObj.ts_rp =
            parseInt(dashboardData.totalSubscribedPin) -
            parseInt(dashboardData.totalMySubscribedPin);
          rootObj.ts_wp = parseInt(dashboardData.totalMySubscribedPin);
          rootObj.ts_sum = parseInt(dashboardData.totalSubscribedPin);

          if (
            parseInt(dashboardData.todayMySubscribedPin) >
            parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          } else if (
            parseInt(dashboardData.todayMySubscribedPin) <
            parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          } else {
            rootObj.opc_today = dashboardData.todayMySubscribedPin;
          }

          if (
            parseInt(dashboardData.todaySubscribedPin) -
              parseInt(dashboardData.todayMySubscribedPin) >
            parseInt(dashboardData.yseterdaySubscribedPin) -
              parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          } else if (
            parseInt(dashboardData.todaySubscribedPin) -
              parseInt(dashboardData.todayMySubscribedPin) <
            parseInt(dashboardData.yseterdaySubscribedPin) -
              parseInt(dashboardData.yseterdayMySubscribedPin)
          ) {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          } else {
            rootObj.rpc_today =
              dashboardData.todaySubscribedPin -
              dashboardData.todayMySubscribedPin;
          }

          rootObj.opc_yes = dashboardData.yseterdayMySubscribedPin;
          rootObj.yestotal = dashboardData.yseterdayMyTotalSubscribedPin;
          rootObj.rpc_yes =
            dashboardData.yseterdaySubscribedPin -
            dashboardData.yseterdayMySubscribedPin;
          rootObj.rpc_sum =
            dashboardData.yesterdayTotalSubscribedPin -
            dashboardData.yseterdayMyTotalSubscribedPin;
          rootObj.life_time_total_pins = dashboardData.lifetimePinSubscription;
          rootObj.life_time_total_pins_price = parseInt(
            dashboardData.lifetimePinSubscriptionSalesValue
          );
          rootObj.totSubscribedPin = dashboardData.totalSubscribedPin;
          rootObj.todSubscribedPin = dashboardData.todaySubscribedPin;
          rootObj.totRecharge = parseInt(dashboardData.totalRecharge);
          rootObj.todRecharge = parseInt(dashboardData.todayRecharge);
          // rootObj.totalConnectedOvpnUsers = parseInt(
          //   dashboardData.totalConnectedOvpnUsers
          // );
          rootObj.totalFreeUsers = parseInt(dashboardData.totalFreeUsers);
          rootObj.totalOnlineSubscribedPin = parseInt(
            dashboardData.totalOnlineSubscribedPin
          );
          rootObj.todayOnlineSubscribedPin = parseInt(
            dashboardData.todayOnlineSubscribedPin
          );
          rootObj.self_indicator = 0;
          if (rootObj.opc_today > rootObj.opc_yes) {
            rootObj.self_indicator = 1;
          }
          if (rootObj.opc_today < rootObj.opc_yes) {
            rootObj.self_indicator = 2;
          }

          rootObj.reseller_indicator = 0;
          if (rootObj.rpc_today > rootObj.rpc_yes) {
            rootObj.reseller_indicator = 1;
          }
          if (rootObj.rpc_today < rootObj.rpc_yes) {
            rootObj.reseller_indicator = 2;
          }
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObj.loading_data = false;
          loader.hide();
          rootObj.com_errorHandel(error);
        });

      setTimeout(function () {
        loader.hide();
      }, 1000);
    },

    populatePaymentArrData(response) {
      this.paymentArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        response.data.data[prop]["description"] = response.data.data[prop][
          "description"
        ].substr(0, 10);
        this.paymentArr.push(response.data.data[prop]);
      }
    },

    populateRechargeArrData(response) {
      //console.log(response);
      this.rechargeArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        this.rechargeArr.push(response.data.data[prop]);
      }
    },

    populateTopResellerArrData(response) {
      this.topResellerArr = response.data.data;
    },

    refreshDashboard() {
      this.loadDashboardData();
      this.forceRerender();
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
  },
  mounted: function () {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
