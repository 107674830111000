<template>
  <PushList />
</template>

<script>
// @ is an alias to /src
import PushList from "@/components/Push/PushLists.vue";

export default {
  name: "PushLists",
  components: {
    PushList,
  },
};
</script>
