<template>
   <div class="content-wrapper">
      <section class="content-header">
         <div class="row">
            <div class="col-xs-8">
               <h1>Search Connected Subscribers</h1>
            </div>
            <div class="col-xs-4">
               <a class="btn btn-sm btn-add refresh" @click="refreshNow">
                  <i class="fa fa-refresh"></i> Refresh
               </a>
            </div>
         </div>
      </section>
      <section class="content">
         <form v-on:submit.prevent="dopagination(0)">
         <div class="row">
            <div class="col-sm-2 col-xs-3">
               <div class="form-group">
                  <filterDropdownOptions />
               </div>
            </div>
            <div class="col-sm-2 col-xs-3 form-group">
               <input placeholder="From" type="text" value="" name="starttime" id="starttime" v-model="starttime" class="form-control input-sm datePicker" autocomplete="off" required="">
            </div>
            <div class="col-sm-2 col-xs-3 form-group">
               <input placeholder="To" type="text" value="" name="stoptime" id="stoptime" v-model="stoptime" class="form-control input-sm datePicker" autocomplete="off" required="">
            </div>
            <div class="col-sm-2 col-xs-3 form-group">
               <input placeholder="Username" type="text" value="" name="username" id="username" v-model="username" class="form-control input-sm" autocomplete="off">
            </div>
            <div class="col-sm-2 col-xs-3">
               <div class="form-group">
                  <select title="" id="login_type" v-model="login_type" v-select="login_type" name="login_type" class="form-control input-sm select2" @change="dopagination(0)">
                     <option value="1" selected="">Last Login</option>
                     <option value="2">Subscribed</option>
                  </select>
               </div>
            </div>
            <div class="col-sm-2 col-xs-3">
               <select title="" name="limit" id="limit" class="form-control input-sm select2" v-select="display_limit" v-model="display_limit" @change="dopagination(0);">
                  <option value="3">3</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
               </select>
            </div>
         </div>
         <div class="row">
            <div class="col-sm-2 col-xs-3 pull-right form-group btnRight" style="">
               <button type="submit" class="btn btn-sm btn-submit">
                  <i class="fa fa-search"></i> Search
               </button>
            </div>
         </div>
         </form>

         <div class="row">
            <div class="col-md-12">
               <div class="box" id="pagination">
                  <div class="box-header">
                     <h3 class="box-title">
                        Total {{totalData}} Row Found 
                        <span v-if="totalData > 0">
                           , Showing {{displayFrom}} to {{displayTo}}
                        </span>
                     </h3>
                  </div>
                  <div class="box-body">
                     <div class="table-responsive">
                        <table class="table table-0024 table-bordered table-striped">
                           <thead>
                              <tr>
                                 <th class="tdCenter tdSN">SN</th>
                                 <th class="tdCenter">Username</th>
                                 <th class="tdCenter">First Login</th>
                                 <th class="tdCenter">Last Login</th>
                                 <th class="tdCenter">Validity Date</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(data, index) in listArr" :key="data.id">
                                 <td class="tdCenter warning tdSN">
                                    {{parseInt(sl) + index}}
                                 </td>
                                 <td class="tdCenter"> 
                                    {{com_removeHostedPinPrefix(data.username, userData.reseller4.hosted_pin_prefix, userData.reseller4.reseller_panel_type)}}
                                 </td>
                                 <td class="tdCenter"> 
                                    {{data.after_activate_first_login_at}}
                                 </td>
                                 <td class="tdCenter"> 
                                    {{data.last_login}}      
                                 </td>
                                 <td class="tdCenter">   
                                    {{data.validity_date}}     
                                 </td>
                              </tr>
                              <td v-if="listArr.length==0" colspan="6" class="tdCenter" style="padding: 30px 0;"><b>No Records
                                                    Found</b></td>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div class="box-footer">
                     <div class="pagination" v-if="last_page > 1">
                        <a v-if="prev_page_url" href="#" @click="dopagination(prevPage)" class="next">
                           « Previous
                        </a>
                        <span v-else class="disabled">« Previous</span>
                        <span v-if="last_page > max_display_pagination">
                           <span v-for="index in last_page">
                              <span v-if="index==1||index==2">
                                 <span class="current" v-if="index==current_page">
                                    {{index}}
                                 </span>
                                 <span v-else>
                                    <a href="#" @click="dopagination(index)">
                                       {{index}}
                                    </a>
                                 </span>
                                 <span v-if="(index==2) && firstDotted">..</span>
                              </span>
                              <span v-if="randomPageNumber.includes(index)">
                                 <span class="current" v-if="index==current_page">
                                    {{index}}
                                 </span>
                                 <span v-else>
                                    <a href="#" @click="dopagination(index)">
                                       {{index}}
                                    </a>
                                 </span>
                              </span>
                              <span v-if="index==last_page||index==last_page-1">
                                 <span v-if="(index==last_page-1) && lastDotted">
                                    ..
                                 </span>
                                 <span class="current" v-if="index==current_page">
                                    {{index}}
                                 </span>
                                 <span v-else>
                                    <a href="#" @click="dopagination(index)">
                                       {{index}}
                                    </a>
                                 </span>
                              </span>
                           </span>
                        </span>
                        <span v-else>
                           <span v-for="index in last_page">
                              <span class="current" v-if="index==current_page">
                                 {{index}}
                              </span>
                              <span v-else>
                                 <a href="#" @click="dopagination(index)">{{index}}</a>
                              </span>
                           </span>
                        </span>
                        <a v-if="next_page_url" href="#" @click="dopagination(nextPage)" class="next">
                           Next »
                        </a>
                        <span v-else class="disabled">Next »</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   import filterDropdownOptions from "@/components/common/filterDropdownOptions.vue";
   import axios from 'axios'
   export default {
      name: "ResellerConnectedUser",
      components: {
         filterDropdownOptions,
      },
      data() {
         return {
            listArr : [],
            userData : {},
            starttime : '',
            stoptime : '',
            username : '',
            login_type : 1,
            displayFrom : 0,   
            displayTo : 0,  
            totalData : 0, 
            display_limit : 50,
            sl : 1,
   
            // Pagination Variables
            prev_page_url : false,
            next_page_url : false,
            last_page : 1,
            current_page : null,
            max_display_pagination : 9,
            randomPageNumber : {}, 
            firstDotted: false,
            lastDotted: false,
            prevPage : '',
            nextPage : '',
         }
      },
      created(){
         var rootObject = this;
         loader.show();
         var encryptedData = this.$store.getters.getUser;
         var decodedString  = this.com_DecryptString(encryptedData);
         this.userData = JSON.parse(decodedString);
         this.filterWiseDateRangeSet();
         this.dopagination(0);
      },
      methods: {
         dopagination(page_no = ''){
            loader.show();
            var rootObject = this;

            const form = new FormData()
            form.append('fromDate', this.starttime)
            form.append('toDate', this.stoptime)
            form.append('username', this.username)
            form.append('login_type', this.login_type);
            form.append('paginate', this.display_limit);
            form.append('short_filter', $("#short_filter").val());

            var config = {
             headers: { 'Content-Type': 'multipart/form-data' }
            }

            if(page_no > 0){
               form.append('page', page_no);
            }
            //console.log(querystr);
   
            axios.post('connected-users', form, config).then(function (response) {
               rootObject.populateSearchedData(response);
            })
            .catch(function (error) {
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += ' '+error.response.data[prop]
                }
                console.log(alertmessage);
                loader.hide();
                rootObject.com_errorHandel(error);
            });
         },
   
         populateSearchedData(response){
            this.sl = parseInt(response.data.from);
            this.listArr = response.data.data;
            this.totalData = response.data.total;
            this.displayFrom = response.data.from;
            this.displayTo = response.data.to;
            this.display_limit = response.data.per_page;
            this.paginationGenerate(response);
            loader.hide();
         },
   
         paginationGenerate(response){
            this.prev_page_url = response.data.prev_page_url;
            this.next_page_url = response.data.next_page_url;
            this.last_page = response.data.last_page;
            this.current_page = response.data.current_page;
            this.randomPageNumber = [];
            this.firstDotted = false;
            this.lastDotted = false;
            this.prevPage = this.current_page-1;
            this.nextPage = this.current_page+1;
            let countableOtherPages = this.max_display_pagination-4;
            if(this.current_page==1 || this.current_page ==2){
               for(let i = 3; i <= (3+(countableOtherPages-1)); i++){
                  this.randomPageNumber.push(i);
               }
               this.lastDotted=true;
            }
            else if(this.current_page==this.last_page || this.current_page ==this.last_page-1){
               let endPage = this.last_page-2;
               for(let i = endPage; i >= endPage - (countableOtherPages-1); i--){
                  this.randomPageNumber.push(i);
               }
               this.firstDotted = true;
            }
            else{
               let interval = parseInt(countableOtherPages / 2);
               if(this.current_page <= (interval+3)){
                  for(let i = 3; i <= (3+(countableOtherPages-1)); i++){
                     this.randomPageNumber.push(i);
                  }
                  this.lastDotted=true;
               }
               else if (this.current_page >= (this.last_page - (interval+2))) 
               {
                  let endPage = this.last_page-2;
                  for(let i = endPage; i >= endPage - (countableOtherPages-1); i--){
                     this.randomPageNumber.push(i);
                  }
                  this.firstDotted = true;
               }else{
                  for(let i = this.current_page; i <= (this.current_page+interval); i++){
                     this.randomPageNumber.push(i);
                  }
   
                  for(let i = (this.current_page-1); i >= (this.current_page-interval); i--){
                     this.randomPageNumber.push(i);
                  }
               }
            }
         },

         filterWiseDateRangeSet()
         {
            $(".datePicker").removeAttr('readonly', 'readonly'); 
            if ($("#short_filter").val()) {
               this.starttime = '';
               this.stoptime = '';
               $(".datePicker").attr('readonly', 'readonly'); 
            }else{
               var dateRangeArr = this.com_currentDateRange();
               this.starttime = dateRangeArr.startDate;
               this.stoptime = dateRangeArr.startDate;
            }
         },

         refreshNow(){
            this.starttime = '';
            this.stoptime = '';
            this.username = '';
            this.login_type = 1;
            this.display_limit = 50;
            $("#short_filter").val('');
            this.filterWiseDateRangeSet();
            this.dopagination(0);
         }
      },
      mounted: function() {
         var rootObject = this;
         
         $('#starttime').datetimepicker({
            timepicker: false,
            format: 'Y-m-d'
         }).on('change', function(e){
            rootObject.starttime = $(this).val();
         });

         $('#stoptime').datetimepicker({
            timepicker: false,
            format: 'Y-m-d'
         }).on('change', function(e){
            rootObject.stoptime = $(this).val();
         });

         $("#short_filter").on('change', function(){
            rootObject.filterWiseDateRangeSet();
            rootObject.dopagination(0);
         });

         $(".select2").select2();
      }
   };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>