<template>
  	<PinSubscriptionReport />
</template>

<script>

// @ is an alias to /src
import PinSubscriptionReport from "@/components/reports/PinSubscriptionReport.vue";

export default {
  name: "PinSubscriptionReportView",
  components: {
    PinSubscriptionReport,
  },
};
</script>
