<template>
  <AddRatePlan />
</template>

<script>
// @ is an alias to /src
import AddRatePlan from "@/components/AddRadiusAuth.vue";

export default {
  name: "AddRadiusAuth",
  components: {
    AddRatePlan,
  },
};
</script>
