<template>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="row">
                <div class="col-xs-6">
                    <h1></h1>
                </div>
                <div class="col-xs-6 mandatoryDiv">
                    <span class="mandatory_color">(*)</span> <span
                        class="letter-right"> Marked are required field</span>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="alert alert-info" v-if="alert_message">
                        {{alert_message}}
                    </div>
                </div>
                <form role="form">
                    <div class="col-sm-6">
                        <div class="box">
                            <div class="box-header">
                                <h3 class="box-title">
                                    Destination Rate Chart Configuration
                                </h3>
                            </div>
                            <div class="box-body form-box-body form-horizontal">
                                <div class="form-group">
                                    <label class="col-md-4 control-label">Plan Name <span
                                            class="mandatory_horizontal">*</span></label>
                                    <div class="col-md-8">
                                        <input name="plan_name" id="plan_name" class="form-control input-sm"
                                               v-model="plan_name" value="" placeholder="name" type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-4 control-label">No Of Days <span class="mandatory_horizontal">*</span></label>
                                    <div class="col-md-8">
                                        <input name="no_of_days" v-model="no_of_days" id="no_of_days"
                                               class="form-control input-sm" value="" placeholder="validity days"
                                               type="text">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-4 control-label">Price <span
                                            class="mandatory_horizontal">*</span></label>
                                    <div class="col-md-8">
                                        <input name="price" id="price" v-model="price" class="form-control input-sm"
                                               value="0" placeholder="Rate" type="number" min="0">
                                        <label v-if="error_price" class="error" for="name">{{error_price}}</label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-4 control-label">Active</label>
                                    <div class="col-md-8">
                                        <input name="status" type="checkbox" v-model="status" id="status"
                                               checked="checked">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 btnCenter">
                        <input type="submit" name="submit" value="Submit" class="btn btn-sm btn-submit btn-submit-big"
                               @click="formSubmit">
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        name: "EditSaleRate",
        data() {
            return {
                id: 0,
                plan_name: '',
                no_of_days: '',
                price: '',
                rate_plan_id: '',
                status: true,
                alert_message: '',
                error_price: false,
            }
        },
        created() {
            var param_id = this.$route.params.id;
            this.rate_plan_id = param_id;
        },
        methods: {
            formSubmit(e) {
                e.preventDefault();
                var rootObject = this;
                if (this.status === true) {
                    var status = 1;
                } else {
                    var status = 2;
                }

                var result = this.fromValidation();
                if (result) {
                    return 0;
                }

                loader.show();
                const form = new FormData()
                form.append('rate_plan_id', this.rate_plan_id)
                form.append('plan_name', this.plan_name)
                form.append('no_of_days', this.no_of_days)
                form.append('price', this.price)
                form.append('status', status)

                var config = {
                    headers: {'Content-Type': 'multipart/form-data'}
                }

                axios.post('rate-plan-details', form, config)
                    .then(function (response) {
                        //console.log(response);
                        rootObject.alert_message = response.data.message;
                        rootObject.plan_name = "";
                        rootObject.no_of_days = "";
                        rootObject.price = "";
                        loader.hide();
                    })
                    .catch(function (error) {
                        loader.hide();
                        rootObject.com_errorHandel(error);
                        let alertmessage = "";
                        for (var prop in error.response.data) {
                            alertmessage += ' ' + error.response.data[prop]
                        }
                        console.log(alertmessage);
                        rootObject.alert_message = alertmessage;
                    });

                setTimeout(function () {
                    rootObject.alert_message = '';
                }, 5000);
            },

            fromValidation(reset = false) {
                var has_error = false;
                if (!this.price) {
                    this.error_price = "This field is required";
                    has_error = true;
                }

                if (has_error) {
                    return true;
                } else {
                    return false;
                }
            },

        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>